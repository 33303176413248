export default [
  {
    title: 'Form',
    name: 'form-managers',
    icon: 'FileTextIcon',
    route: 'form-managers-list',
    authorize: ['su', 'sa'],
    // children: [
    //   {
    //     title: 'List',
    //     route: 'form-managers-list',
    //   },
    //   {
    //     title: 'Create',
    //     route: 'form-managers-create',
    //   },
    // ],
  },
  {
    title: 'Landing Page',
    name: 'landing-pages',
    iconType: 'Bootstrap',
    icon: 'bi bi-laptop',
    route: 'landing-pages-list',
    authorize: ['su', 'sa'],
    // children: [
    //   {
    //     title: 'List',
    //     route: 'landing-pages-list',
    //   },
    //   {
    //     title: 'Create',
    //     route: 'landing-pages-create',
    //   },
    // ],
  },
  {
    title: 'Leads',
    name: 'leads',
    icon: 'CheckSquareIcon',
    route: 'leads-list',
    authorize: ['su', 'sa'],
    // children: [
    //   {
    //     title: 'List',
    //     route: 'leads-list',
    //   },
    // ],
  },
  // {
  //   title: 'User List',
  //   icon: 'UserIcon',
  //   route: 'users',
  // },
  {
    title: 'Settings',
    name: 'user-setting',
    route: 'user-setting',
    icon: 'SettingsIcon',
    authorize: ['su', 'sa'],
  },
]
