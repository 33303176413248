<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <template
      v-for="item in items"
    >
      <component
        :is="resolveNavComponent(item)"
        v-if="item.authorize ? roleCode !== '' ? item.authorize.includes(roleCode) :false : false"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const roleCode = ref('')
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    const getSelf = () => {
      store.dispatch('app-user/getSelf')
        .then(response => {
          store.state.currentUser = response.data
          roleCode.value = response.data.role.code
        })
    }
    getSelf()

    return {
      roleCode,
      resolveNavComponent,
    }
  },
}
</script>

<style>
  #main-menu-navigation li {
    padding: 0 !important;
  }
  #main-menu-navigation {
    justify-content: center;
  }
</style>
